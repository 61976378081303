<template>
  <div id="page-banner" class="page-head head-index columns">
    <div
      class="banner-container column is-full-mobile is-full-tablet is-half-desktop is-half-fullhd"
    >
      <img class="banner-logo-text" src="../../../assets/icons/logo-banner.png" />
      <!-- <img
        class="banner-logo-text"
        src="../../../assets/icons/shinier_text.webp"
      /> -->
      <h1 class="slogan">
        Levamos a tecnologia da sua empresa do produto mínimo ao escalar.
      </h1>
      <p class="vsl">
        Você sabe como é difícil levar sua ideia de tecnologia adiante? Bem, nós
        entendemos esse desafio e temos a solução perfeita para você. Conheça a
        Shinier, a aceleradora de tecnologia com sucessos de empresas e setores
        que começaram do zero conosco e estão faturando mais de seis dígitos por
        ano.
      </p>
    </div>

    <iframe
      class="iframe-form column is-full-mobile is-full-tablet is-full-desktop is-half-fullhd"
      style="border-radius: 4px"
      width="100%"
      height="650"
      src="https://demoquiz.quizclass.net/perguntas-de-qualificacao"
      title="Quiz"
      frameborder="0"
      sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
      allow="camera https://demoquiz.quizclass.net/perguntas-de-qualificacao; microphone https://demoquiz.quizclass.net/perguntas-de-qualificacao"
    ></iframe>
  </div>
</template>
<script>
export default {};
</script>
<style scoped src="./index.css"></style>
